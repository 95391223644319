/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react'
import Button from '../button'
import Checkbox from '../checkbox'
import { CaretLeft, CaretRight, CaretDown, CaretUp } from '@phosphor-icons/react'

interface Column {
  header: React.ReactNode
  accessor: string
  className?: string
}

interface RowData {
  id?: string
  [key: string]: React.ReactNode | (() => React.ReactNode)
}

interface TableProps {
  className?: string
  hasBackButton?: boolean
  columns: Column[]
  data: RowData[]
  currentPage?: number
  totalPages?: number
  isLoading?: boolean
  onPageChange?: (newPage: number) => void
  onClickRow?: (row: RowData) => void
  onSelectRow?: (selectedRows: string[]) => void
  selectable?: boolean
  sort?: string[]
  emptyDataLabel?: string
}

const Table: React.FC<TableProps> = ({
  hasBackButton,
  columns,
  data,
  className,
  isLoading,
  currentPage = 0,
  totalPages = 0,
  onPageChange,
  onClickRow,
  onSelectRow,
  selectable = false,
  sort = [],
  emptyDataLabel = 'Sem dados encontrados'
}) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [sortState, setSortState] = useState<{ [key: string]: 'asc' | 'desc' | null }>({})

  useEffect(() => {
    const initialSortState: { [key: string]: 'asc' | 'desc' | null } = {}
    sort.forEach(accessor => {
      initialSortState[accessor] = null
    })
    setSortState(initialSortState)
  }, [])

  const handleSelectRow = (rowId: string) => {
    const isSelected = selectedRows.includes(rowId)
    const newSelectedRows = isSelected
      ? selectedRows.filter(id => id !== rowId)
      : [...selectedRows, rowId]
    setSelectedRows(newSelectedRows)
    onSelectRow?.(newSelectedRows)
  }

  const renderCell = (cellData: React.ReactNode | (() => React.ReactNode)) => {
    return typeof cellData === 'function' ? cellData() : cellData
  }

  const handleSort = (accessor: string) => {
    if (!sort.includes(accessor)) {
      return
    }

    setSortState(prevState => {
      const currentSortOrder = prevState[accessor] || null
      const newSortOrder: 'asc' | 'desc' | null = currentSortOrder === 'asc' ? 'desc' : 'asc'

      return {
        [accessor]: newSortOrder
      }
    })
  }

  const getStatusValue = (status: React.ReactNode): string => {
    if (typeof status === 'string') {
      return status.trim()
    }

    if (React.isValidElement(status)) {
      const children = status.props?.children
      if (typeof children === 'string') {
        return children.trim()
      }

      if (Array.isArray(children)) {
        return children.map(child => (typeof child === 'string' ? child.trim() : '')).join('')
      }
    }

    const statusString = status?.toString() || ''
    return statusString.replace(/●/, '').trim()
  }

  const getCodeValue = (code: React.ReactNode): string => {
    if (typeof code === 'string') {
      return code.trim()
    }

    if (React.isValidElement(code)) {
      const children = code.props?.label.toString()
      if (typeof children === 'string') {
        return children.trim()
      }

      if (Array.isArray(children)) {
        return children.map(child => (typeof child === 'string' ? child.trim() : '')).join('')
      }
    }

    return code?.toString() || ''
  }

  const sortedData = () => {
    const activeSortColumns = Object.keys(sortState).filter(col => sortState[col] !== null)

    if (activeSortColumns.length === 0) {
      return data
    }

    return [...data].sort((a, b) => {
      for (const column of activeSortColumns) {
        let aValue = a[column]
        let bValue = b[column]
        const sortOrder = sortState[column]

        if (column === 'hour') {
          const parseTime = (time: string | null | undefined) => {
            if (!time) return 0
            const [hours, minutes] = time.split(':').map(Number)
            return hours * 60 + minutes
          }
          aValue = parseTime(aValue as string)
          bValue = parseTime(bValue as string)
        } else if (column === 'date') {
          const parseDate = (date: string | null | undefined) => {
            if (!date) return 0
            if (date.includes(':')) {
              const [day, month, yearAndTime] = date.split('/')
              const [year, time] = yearAndTime.split(' ')
              const [hours, minutes] = time.split(':').map(Number)
              return new Date(
                Number(year),
                Number(month) - 1,
                Number(day),
                hours,
                minutes
              ).getTime()
            } else {
              const [day, month, year] = date.split('/').map(Number)
              return new Date(year, month - 1, day).getTime()
            }
          }
          aValue = parseDate(aValue as string)
          bValue = parseDate(bValue as string)
        } else if (column === 'status') {
          //@ts-expect-error
          aValue = getStatusValue(aValue)
          //@ts-expect-error
          bValue = getStatusValue(bValue)
        } else if (column === 'code') {
          //@ts-expect-error
          aValue = getCodeValue(aValue)
          //@ts-expect-error
          bValue = getCodeValue(bValue)
        }

        if (aValue == null) return sortOrder === 'asc' ? -1 : 1
        if (bValue == null) return sortOrder === 'asc' ? 1 : -1
        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1
      }
      return 0
    })
  }

  const tableSize =
    className ||
    (hasBackButton
      ? 'max-h-[calc(100dvh-304px)] sm:max-h-[calc(100dvh-380px)]'
      : 'max-h-[calc(100dvh-260px)] sm:max-h-[calc(100dvh-298px)]')

  const renderIconSort = (sortOrder: 'asc' | 'desc' | null | undefined) => {
    if (sortOrder === 'asc') {
      return (
        <span className='ml-4'>
          <CaretUp size={16} className='text-blue-500' />
        </span>
      )
    }
    if (sortOrder === 'desc') {
      return (
        <span className='ml-4'>
          <CaretDown size={16} className='text-blue-500' />
        </span>
      )
    }
    return (
      <span className='ml-3 mt-[-8px] text-label-sm font-medium text-neutralContent-tertiary '>
        <CaretUp size={16} className='text-gray-300' />
        <CaretDown size={16} className='text-gray-300' />
      </span>
    )
  }

  return (
    <div
      className={`mx-[-16px] flex flex-1 flex-col border-neutralBorder-default sm:mx-0  sm:rounded-xl sm:border sm:px-0 ${tableSize}`}
    >
      <div className='hidden w-full border-b border-neutralBorder-default sm:flex'>
        {selectable && (
          <div className='flex w-[40px] p-4 text-left'>
            <Checkbox
              isChecked={selectedRows.length === data.length && data.length > 0}
              isIndeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
              onClick={() => {
                if (selectedRows.length === data.length) {
                  setSelectedRows([])
                  onSelectRow?.([])
                } else {
                  const allIds = data.map(row => row.id as string)
                  setSelectedRows(allIds)
                  onSelectRow?.(allIds)
                }
              }}
            />
          </div>
        )}

        {columns.map((column, index) => {
          const isSortedColumn = sort.includes(column.accessor)
          const sortOrder = sortState[column.accessor]
          return (
            <div
              key={index}
              className={`flex flex-1 overflow-hidden p-4 text-left text-label-sm font-medium text-neutralContent-tertiary ${column.className}`}
              onClick={() => handleSort(column.accessor)}
              style={{ cursor: 'pointer' }}
            >
              <span>{column.header}</span>
              {isSortedColumn && sort.length > 0 && <>{renderIconSort(sortOrder)}</>}
            </div>
          )
        })}
      </div>

      {isLoading ? (
        <BodySkeleton columns={columns} length={data.length} selectable={selectable} />
      ) : data.length === 0 ? (
        <div className='flex items-center justify-center p-4 text-center text-label-lg text-neutralContent-tertiary'>
          {emptyDataLabel}
        </div>
      ) : (
        <div className='flex flex-1 flex-col gap-3 overflow-y-auto px-2 pb-4 sm:gap-0 sm:px-0 sm:pb-0'>
          {sortedData().map((row, rowIndex) => (
            <div
              key={rowIndex}
              onClick={onClickRow ? () => onClickRow(row) : undefined}
              className={`flex flex-col flex-wrap items-center gap-4 rounded-lg border p-4 transition-all sm:flex-row sm:items-center sm:gap-0 sm:rounded-none sm:border-x-0 sm:border-t-0 sm:p-0 ${
                onClickRow && 'cursor-pointer hover:bg-gray-50'
              }`}
            >
              {selectable && (
                <div className='w-[40px] p-4'>
                  <Checkbox
                    isChecked={selectedRows.includes(row.id as string)}
                    onClick={() => handleSelectRow(row.id as string)}
                  />
                </div>
              )}
              {columns.map((column, colIndex) => (
                <div
                  key={rowIndex + '_' + colIndex}
                  className={`flex w-full flex-1 flex-col overflow-hidden text-label-sm text-neutralContent-primary sm:flex-row sm:p-4 ${column.className}`}
                >
                  {column.header && (
                    <label className='mb-1 text-label-sm font-semibold text-neutralContent-tertiary sm:hidden'>
                      {column.header}
                    </label>
                  )}
                  {renderCell(row[column.accessor])}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {!!totalPages && (
        <div className='flex items-center justify-center border-t border-neutralBorder-default px-4 py-2'>
          <Button
            disabled={currentPage <= 0}
            onClick={() => onPageChange?.(currentPage - 1)}
            iconLeft={<CaretLeft />}
            size='sm'
            variant='secondary'
            state={currentPage <= 0 ? 'disable' : 'enable'}
          />
          <span className='mx-2 text-label-sm font-medium text-neutralContent-tertiary'>
            Página {currentPage + 1} de {totalPages}
          </span>
          <Button
            disabled={currentPage >= totalPages - 1}
            onClick={() => onPageChange?.(currentPage + 1)}
            iconLeft={<CaretRight />}
            size='sm'
            variant='secondary'
            state={currentPage >= totalPages - 1 ? 'disable' : 'enable'}
          />
        </div>
      )}
    </div>
  )
}

const BodySkeleton: React.FC<{ columns: Column[]; length?: number; selectable?: boolean }> = ({
  columns,
  length,
  selectable = false
}) => {
  return (
    <div className='mx-4 flex flex-1 flex-col gap-4 overflow-y-hidden sm:mx-0 sm:gap-0'>
      {Array.from({ length: length || 5 }, (_, index_row) => (
        <div
          className='flex items-center overflow-hidden rounded-xl border sm:h-[60px] sm:flex-row sm:rounded-none sm:border-x-0'
          key={index_row}
        >
          {selectable && (
            <div className='w-[40px] p-4'>
              <div className='flex h-6 w-6 animate-pulse rounded-md bg-gray-200' />
            </div>
          )}
          {columns.map((column, index_col) => (
            <div
              key={index_row + '_' + index_col}
              className={`flex h-40 flex-1 animate-pulse bg-gray-200 text-label-sm text-neutralContent-primary sm:mx-4 sm:h-6 sm:rounded ${column.className}`}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Table
